//
// jarallax.js
// Theme module
//

import { jarallax, jarallaxVideo } from 'jarallax';

const toggles = document.querySelectorAll('[data-jarallax]');

// Add Video extension
jarallaxVideo();

// Init Jarallax
jarallax(toggles);

// Make available globally
window.jarallax = jarallax;
window.jarallaxVideo = jarallaxVideo;
